@tailwind base;

@tailwind components;

@tailwind utilities;

html,
body {
  color: #AEA8A5;
}

table.cuz-table {
  border-collapse: separate;
  border-spacing: 0 8px;
  min-width: 100%;
}

.cuz-table thead th:first-child {
  padding-left: 24px;
}

.cuz-table thead th:last-child {
  padding-right: 24px;
}

.cuz-table tr td:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding-left: 24px;
}

.cuz-table tr td:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-right: 24px;
}

.bg-gradient-blue {
  background: linear-gradient(180deg, #1E90FF 0%, #125699 100%);
}

.bg-gradient-green {
  background: linear-gradient(180deg, #2ED573 0%, #1C8045 100%);
}

.bg-gradient-red {
  background: linear-gradient(180deg, #FF6961 0%, #662A27 100%);
}

.capital .react-datepicker .react-datepicker__triangle {
  display: none;
}

.capital .react-datepicker .react-datepicker__year-text {
  width: 56px;
}